<template>
  <span v-if="statusMap.has(status)" class="status" :style="{background: statusMap.get(status)[0],color: statusMap.get(status)[1]}">{{ $codeNew(statusList, status) }}</span>
</template>
<script>
export default {
  props: {
    status: {
      type: [Number, String],
      default: () => (0)
    },
    statusMap: Map,
    statusList: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
<style lang="scss" scoped>
    .status {
        font-size: 14px;
    }
</style>
